import type { Product } from '../../../../../../shared/apiFetchers/embedProductsFetcher';
import type { DefaultPlan, Plan, ProductStateId } from '../../../../../../shared/types';

export const getSelectablePlans = (
  product: Product,
  productStateId: ProductStateId | ''
): Array<Plan> => {
  return [
    ...(((product.monthly_rental_offer && productStateId === 'reuse') ||
    (product.unused_monthly_rental_offer && productStateId === 'new')
      ? [{ id: 'monthly', name: '月額制プラン' }]
      : []) as Array<Plan>),
    ...(((product.rental_offer && productStateId === 'reuse') ||
    (product.unused_rental_offer && productStateId === 'new')
      ? [{ id: 'onetime', name: 'ワンタイムプラン' }]
      : []) as Array<Plan>),
    ...((product.sell_offer && productStateId === 'new'
      ? [{ id: 'sales', name: '販売' }]
      : []) as Array<Plan>),
  ];
};

export const getDefaultPlan = (
  planItems: Array<Plan>,
  defaultPlan?: DefaultPlan
): '' | 'monthly' | 'onetime' | 'sales' => {
  if (!defaultPlan) {
    return planItems.length > 0 ? planItems[0].id : '';
  }
  if (defaultPlan === 'monthly_rental') {
    const monthlyItem = planItems.find((item) => item.id === 'monthly');
    if (monthlyItem) {
      return monthlyItem.id;
    }
  }
  if (defaultPlan === 'rental') {
    const onetimeItem = planItems.find((item) => item.id === 'onetime');
    if (onetimeItem) {
      return onetimeItem.id;
    }
  }
  if (defaultPlan === 'sell') {
    const salesItem = planItems.find((item) => item.id === 'sales');
    if (salesItem) {
      return salesItem.id;
    }
  }
  return '';
};
