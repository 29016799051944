import type { CalendarWeekAttribute } from '../../../../../../shared/apiFetchers/variantsCalendarFetcher';
import { IconOk } from './IconOk';
import { IconStrike } from './IconStrike';

export const getTableDataContent = ({
  capacity,
  attributes,
  date,
  selectedDate,
  selectedFromDate,
  selectedToDate,
}: {
  capacity: number;
  attributes: CalendarWeekAttribute[];
  date: string;
  selectedDate: string;
  selectedFromDate?: string;
  selectedToDate?: string;
}): {
  selected: boolean;
  disabled: boolean;
  content: Element | string;
  classNames: string[];
} => {
  const isReturnDayPicker = !!selectedFromDate;

  // 選択状態を示すハイライト表示の属性
  // お届け日〜返却日期間も対象とする
  const selected =
    selectedDate === date ||
    (!!selectedFromDate && selectedFromDate <= date && date <= selectedDate) ||
    (!!selectedToDate && selectedDate <= date && date <= selectedToDate);

  // 在庫状態の表示テキスト
  let content: Element | string = 2 < capacity ? IconOk() : '残' + capacity;

  // 選択の可不可の属性
  let disabled = false;

  // 祝日・日曜日・土曜日の表示の属性
  let classNames: string[] = [];

  if (isReturnDayPicker && date < selectedFromDate) {
    content = IconStrike();
    disabled = true;
  } else if (Number(capacity) === 0) {
    content = IconStrike();
    disabled = true;
  }

  attributes.forEach((attr) => {
    if (attr === 'outOfRange') {
      content = IconStrike();
      disabled = disabled || !isReturnDayPicker; // 返却日指定時は選択可
    } else if (attr === 'unselectable') {
      content = IconStrike();
      disabled = true;
    } else if (attr === 'saturday') classNames?.push('-saturday');
    else if (attr === 'sunday' || attr === 'holiday') classNames.push('-sunday');
    else if (attr === 'today') classNames.push('-today');
  });

  return {
    selected,
    disabled: !(selectedDate === date) && disabled,
    content,
    classNames,
  };
};
