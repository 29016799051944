import type { PromiseWithStatus } from '../../utils/withStatusHandling';
import { rentifyApiFetcher } from '../shared/rentifyApiFetcher';

export interface Settings {
  primary_color: string;
  button_text: string;
  font_color: string;
  font_size: string;
  font_weight: string;
  border_color: string;
  border_width: string;
  border_radius: string;
  width: string;
  height: string;
  use_icon: boolean;
  front_domain: string;
  modal_primary_color: string;
}

interface EmbedButtonSettingsData {
  settings: Settings;
}

const endPoint = '/embed/button_settings';

export const embedButtonSettingsFetcher = async ({
  embedDomain,
}: {
  embedDomain: string;
}): PromiseWithStatus<EmbedButtonSettingsData> => {
  // OKパターン
  // return {
  //   ok: true,
  //   data: {},
  // };

  // NGパターン
  // return {
  //   ok: false,
  //   error: new Error('ボタン設定取得APIでエラーが発生しました！'),
  // };

  // 本来の実装
  return await rentifyApiFetcher<EmbedButtonSettingsData>(
    endPoint,
    {},
    { 'embed-domain': embedDomain }
  );
};
