
const digest = '6710315c77d79d0b4ca3cda29e6e5d6027d4eff06784bb64105d21036b439b9f';
const css = `._properties_heu7w_1 {
  --color-teal-500: #319795;
  --color-black: #000;
  --color-black-alpha-100: rgba(0, 0, 0, 0.06);
  --color-white: #fff;
  --color-white-100: #fff;
  --color-gray-100: #edf2f7;
  --color-gray-200: #e2e8f0;
  --color-gray-300: #cbd5e0;
  --color-gray-500: #718096;
  --color-gray-700: #2d3748;
  --color-gray-800: #1a202c;
  --color-red-500: #e53e3e;
  --color-red-600: #c53030;
  --color-orange-500: #dd6b20;
  --color-blue-500: #3182ce;
  --color-cyan-800: #086f83;

  --shadow-dark-lg: 0px 15px 40px 0px rgba(0, 0, 0, 0.4), 0px 5px 10px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 1px rgba(0, 0, 0, 0.1);

  --button-primary-bg-color: var(--color-teal-500);

  --selected-date-bg-color: var(--color-teal-500);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"properties":"_properties_heu7w_1"};
export { css, digest };
  