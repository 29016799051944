export const openAnimatedDialog = (dialog: HTMLDialogElement, opener: HTMLButtonElement) => {
  // transitionのために遅れてclassを付与したい
  // openイベントはないのでrAFで代用
  requestAnimationFrame(() => dialog.classList.remove('-closing'));
  opener.setAttribute('aria-expanded', 'true');
  dialog.showModal();
};

export const closeAnimatedDialog = (
  dialog: HTMLDialogElement,
  opener: HTMLButtonElement,
  callback?: () => void
) => {
  dialog.addEventListener(
    'transitionend',
    ({ currentTarget }) => {
      if (!(currentTarget instanceof HTMLDialogElement)) {
        return;
      }
      currentTarget.close();
      callback && callback();
    },
    { once: true }
  );

  dialog.classList.add('-closing');
  opener.setAttribute('aria-expanded', 'false');
};
