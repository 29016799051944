import type { Product } from '../../../../../../shared/apiFetchers/embedProductsFetcher';
import type { PlanId, ProductStateId } from '../../../../../../shared/types';
import { getPlanInfo as _getPlanInfo, type PlanInfo } from './getPlanInfo';
import { getDefaultPlan, getSelectablePlans as _getSelectablePlans } from './getSelectablePlans';
import {
  getDefaultProductStateId,
  getSelectableProductState as _getSelectableProductState,
} from './getSelectableProductState';
import {
  variantsCalendarFetcher,
  type CalendarDay,
} from '../../../../../../shared/apiFetchers/variantsCalendarFetcher';
import { pickNowDateFromCalendar } from './pickNowDateFromCalendar';
import { addDays } from './addDays';
import { validatePeriod } from './validatePeriod';
import { dateDiffInDays } from './dateDiffInDays';
import { getNextPageUrl } from './getNextPageUrl';

export type OrderCtx = ReturnType<typeof useOrder>;

let cache: OrderCtx | null = null;

export const provideOrder = (v: OrderCtx): OrderCtx => {
  // レンタルダイアログの再表示で複数回ctxを生成するため、以下はコメントアウトする
  // if (cache !== null) {
  //   throw new Error('Order is already provided');
  // }
  cache = v;
  return v;
};

export const injectOrder = (): OrderCtx => {
  if (cache === null) {
    throw new Error('Order is not provided');
  }
  return cache;
};

export const useOrder = ({
  embedDomain,
  frontDomain, // rentioのサイト（ex. www.rentify.dev）
  product,
}: {
  embedDomain: string;
  frontDomain: string;
  product: Product;
}) => {
  // レンタルダイアログの再表示で複数回ctxを生成するため、以下はコメントアウトする
  // if (cache !== null) {
  //   throw new Error('Order is already provided');
  // }

  const maximumPeriod = 30;

  const state = {
    initialized: false,
    selectedProductStateId: '' as ProductStateId | '',
    selectedPlanId: '' as PlanId | '',
    selectedVariantId: '' as string,
    selectedDeliveryDate: '' as string,
    selectedReturnDate: '' as string,
    deliveryDays: [] as Array<CalendarDay>,
    nowDate: '',
    deliveryDateErrorMessages: [] as string[],
    returnDateErrorMessages: [] as string[],
    showPlanChangeNotification: false,
  };

  const handler = {
    dialogContentRender: () => {},
    rentalDialogRender: () => {},
  };

  const getMinimumPeriod = () => Number(product.rental_offer?.minimum_period || 0);

  const getSelectableProductState = () => _getSelectableProductState(product);
  state.selectedProductStateId = getDefaultProductStateId(
    getSelectableProductState(),
    product.default_selection.condition
  );

  const getSelectablePlans = () => _getSelectablePlans(product, state.selectedProductStateId);
  state.selectedPlanId = getDefaultPlan(getSelectablePlans(), product.default_selection.plan);

  if (product.variants.length) state.selectedVariantId = String(product.variants[0].id);

  const prepareDeliveryDate = async () => {
    if (state.selectedProductStateId === '' || state.selectedPlanId === '') {
      throw new Error('productStateIdまたはplanIdが不正です');
    }
    const res = await variantsCalendarFetcher({
      embedDomain,
      variantId: state.selectedVariantId,
      productStateId: state.selectedProductStateId,
      planId: state.selectedPlanId,
    });
    if (!res.ok) {
      throw new Error('カレンダーデータ取得APIでエラーが発生しました');
    }
    state.selectedDeliveryDate = res.data.calendar.nearestSelectableDate || '';

    state.selectedReturnDate =
      state.selectedPlanId === 'onetime' && state.selectedDeliveryDate !== ''
        ? addDays(state.selectedDeliveryDate, getMinimumPeriod())
        : '';
    state.deliveryDays = res.data.calendar.weeks.flat();
    state.nowDate = pickNowDateFromCalendar(state.deliveryDays);
  };

  const fetchDeliveryDateToRender = async () => {
    await prepareDeliveryDate();
    handler.dialogContentRender();
  };

  const changeDeliveryDateToRender = (v: string) => {
    state.deliveryDateErrorMessages = [];
    state.returnDateErrorMessages = [];
    state.showPlanChangeNotification = false;

    if (state.selectedPlanId === 'onetime') {
      const minimumPeriod = getMinimumPeriod();
      const validate = () =>
        validatePeriod({
          validationDateType: 'deliveryDate',
          deliveryDays: state.deliveryDays,
          deliveryDate: v,
          returnDate: state.selectedReturnDate,
          minimumPeriod,
          maximumPeriod,
        });

      let validated = validate();

      // 発送日を変更した結果が期間が最小期間未満の場合、返却日を最小期間になるよう変更する
      if (validated.underMinimumPeriodCheck.ok === false) {
        state.selectedReturnDate = addDays(v, minimumPeriod);

        //　補正した上で再度バリデーションを行う
        validated = validate();
      }
      if (validated.overMaximumPeriodCheck.ok === false) {
        state.deliveryDateErrorMessages.push(validated.overMaximumPeriodCheck.message);
      }
      if (validated.outOfStockDayCheck.ok === false) {
        state.deliveryDateErrorMessages.push(validated.outOfStockDayCheck.message);
      }
    }

    state.selectedDeliveryDate = v;
    handler.dialogContentRender();
  };

  const changeReturnDateToRender = (v: string) => {
    state.deliveryDateErrorMessages = [];
    state.returnDateErrorMessages = [];
    state.showPlanChangeNotification = false;

    const validated = validatePeriod({
      validationDateType: 'returnDate',
      deliveryDays: state.deliveryDays,
      deliveryDate: state.selectedDeliveryDate,
      returnDate: v,
      minimumPeriod: getMinimumPeriod(),
      maximumPeriod,
    });

    if (validated.underMinimumPeriodCheck.ok === false) {
      state.returnDateErrorMessages.push(validated.underMinimumPeriodCheck.message);
    }
    if (validated.overMaximumPeriodCheck.ok === false) {
      state.returnDateErrorMessages.push(validated.overMaximumPeriodCheck.message);
    }
    if (validated.outOfStockDayCheck.ok === false) {
      state.returnDateErrorMessages.push(validated.outOfStockDayCheck.message);
    }

    state.selectedReturnDate = v;
    handler.dialogContentRender();
  };

  const changeSelectedProductStateToRender = (v: ProductStateId) => {
    state.selectedProductStateId = v;
    state.showPlanChangeNotification = false;
    if (
      state.selectedPlanId !== '' &&
      getSelectablePlans()
        .map((item) => item.id)
        .includes(state.selectedPlanId) === false
    ) {
      state.selectedPlanId = getDefaultPlan(getSelectablePlans());
      state.showPlanChangeNotification = true;
    }
    handler.dialogContentRender();
    fetchDeliveryDateToRender();
  };

  const changeSelectedPlanToRender = (v: PlanId) => {
    state.selectedPlanId = v;
    state.showPlanChangeNotification = false;
    handler.dialogContentRender();
    fetchDeliveryDateToRender();
  };

  const changeSelectedVariantToRender = (v: string) => {
    state.selectedVariantId = v;
    state.showPlanChangeNotification = false;
    handler.dialogContentRender();
    fetchDeliveryDateToRender();
  };

  return {
    get product() {
      return product;
    },

    getNextPageUrl() {
      if (state.selectedProductStateId === '' || state.selectedPlanId === '')
        throw new Error('カート追加後ページに遷移するためのパラメータが設定されていません。');

      return getNextPageUrl({
        frontDomain,
        variantId: state.selectedVariantId,
        productStateId: state.selectedProductStateId,
        planId: state.selectedPlanId,
        deliveryDate: state.selectedDeliveryDate,
        returnDate: state.selectedReturnDate,
      });
    },

    getSelectableProductState,

    getSelectedProductStateId() {
      return state.selectedProductStateId;
    },

    getSelectedPlanId() {
      return state.selectedPlanId;
    },

    getSelectedVariantId() {
      return state.selectedVariantId;
    },

    getProductImageUrl() {
      const variantId = state.selectedVariantId;
      const productImage = product.images.find(
        (image) => image.variant_id.toString() === variantId
      );
      return productImage ? productImage?.public_image_url : product.images[0].public_image_url;
    },

    getProductImageAlt() {
      const variantId = state.selectedVariantId;
      const productImage = product.images.find(
        (image) => image.variant_id.toString() === variantId
      );
      return productImage ? productImage?.alt : product.images[0].alt;
    },

    getSelectablePlans,

    getPlanInfo(): PlanInfo {
      if (state.selectedProductStateId === '' || state.selectedPlanId === '') {
        throw new Error('productStateIdまたはplanIdが不正です');
      }
      return _getPlanInfo({
        product,
        productStateId: state.selectedProductStateId,
        planId: state.selectedPlanId,
      });
    },

    getNowDate() {
      return state.nowDate;
    },

    getSelectedDeliveryDate() {
      return state.selectedDeliveryDate;
    },

    getDeliveryDays() {
      return state.deliveryDays;
    },

    getSelectedReturnDate() {
      return state.selectedReturnDate;
    },

    getDeliveryDateErrorMessages() {
      return state.deliveryDateErrorMessages;
    },

    getReturnDateErrorMessages() {
      return state.returnDateErrorMessages;
    },

    getSpecifiedPeriodInfo() {
      if (state.selectedReturnDate === '' || state.selectedDeliveryDate === '') {
        return '';
      }
      const diffDays = dateDiffInDays(state.selectedReturnDate, state.selectedDeliveryDate);
      return `${diffDays}泊${diffDays + 1}日の期間が選択されました。`;
    },

    getIsShowReturnDate() {
      return this.getSelectedPlanId() === 'onetime' && this.getSpecifiedPeriodInfo() !== '';
    },

    getIsShowPlanChangeNotification() {
      return state.showPlanChangeNotification;
    },

    setDialogContentRender(v: () => void) {
      handler.dialogContentRender = v;
    },

    fetchDeliveryDateToRender,

    changeDeliveryDateToRender,

    changeReturnDateToRender,

    changeSelectedPlanToRender,

    changeSelectedProductStateToRender,

    changeSelectedVariantToRender,
  };
};
