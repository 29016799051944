import type {
  CalendarDay,
  CalendarWeekAttribute,
} from '../../../../../../shared/apiFetchers/variantsCalendarFetcher';
import { dateDiffInDays } from './dateDiffInDays';

const hasOutOfStockDayInPeriod = ({
  deliveryDays,
  deliveryDate,
  returnDate,
}: {
  deliveryDays: Array<CalendarDay>;
  deliveryDate: string;
  returnDate: string;
}) => {
  let hasOutOfStockDay = false;
  const startDate = new Date(deliveryDate);
  const endDate = new Date(returnDate);
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const date = d.getDate().toString().padStart(2, '0');
    const currentDate = `${year}-${month}-${date}`;
    const calendarDate = deliveryDays.find((day) => day.date === currentDate);
    if (
      !calendarDate ||
      calendarDate.capacity === 0 ||
      calendarDate.attributes.find((attr: CalendarWeekAttribute) =>
        ['outOfStock', 'unselectable'].includes(attr)
      )
    ) {
      hasOutOfStockDay = true;
      break;
    }
  }
  return hasOutOfStockDay;
};

interface ValidatedState {
  outOfStockDayCheck: { ok: boolean; message: string };
  underMinimumPeriodCheck: { ok: boolean; message: string };
  overMaximumPeriodCheck: { ok: boolean; message: string };
}

export const validatePeriod = ({
  validationDateType,
  deliveryDays,
  deliveryDate,
  returnDate,
  minimumPeriod,
  maximumPeriod,
}: {
  validationDateType: 'deliveryDate' | 'returnDate';
  deliveryDays: Array<CalendarDay>;
  deliveryDate: string;
  returnDate: string;
  minimumPeriod: number;
  maximumPeriod: number;
}): ValidatedState => {
  const validatedState: ValidatedState = {
    outOfStockDayCheck: { ok: true, message: '' },
    underMinimumPeriodCheck: { ok: true, message: '' },
    overMaximumPeriodCheck: { ok: true, message: '' },
  };

  if (
    hasOutOfStockDayInPeriod({
      deliveryDays,
      deliveryDate,
      returnDate,
    })
  ) {
    validatedState.outOfStockDayCheck = {
      ok: false,
      message: 'レンタル期間にレンタルできない日付が含まれます。期間を再入力してください。',
    };
  }
  const diffDays = dateDiffInDays(returnDate, deliveryDate);
  const isUnderMinimumPeriod = diffDays < minimumPeriod;
  const isOverMaximumPeriod = maximumPeriod < diffDays;

  if (validationDateType === 'deliveryDate') {
    if (isUnderMinimumPeriod) {
      validatedState.underMinimumPeriodCheck = {
        ok: false,
        message: `レンタル期間は${maximumPeriod}泊${maximumPeriod + 1}日以内を選択してください。`,
      };
    }
    if (isOverMaximumPeriod) {
      validatedState.overMaximumPeriodCheck = {
        ok: false,
        message: `レンタル期間は${maximumPeriod}泊${maximumPeriod + 1}日以上を選択してください。`,
      };
    }
  }
  if (validationDateType === 'returnDate') {
    if (isUnderMinimumPeriod) {
      validatedState.underMinimumPeriodCheck = {
        ok: false,
        message: `レンタル期間は${minimumPeriod}泊${minimumPeriod + 1}日以上を選択してください。`,
      };
    }
    if (isOverMaximumPeriod) {
      validatedState.overMaximumPeriodCheck = {
        ok: false,
        message: `レンタル期間は${maximumPeriod}泊${maximumPeriod + 1}日以内を選択してください。`,
      };
    }
  }
  return validatedState;
};
