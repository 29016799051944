export const styleFormat = (
  obj: {
    [name: string]: any;
  },
  importantApply = true
): string | undefined => {
  const buf: string[] = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if ([undefined, null, ''].includes(value) === false) {
      buf.push(
        `${key}: ${String(value)}` + (importantApply ? '!important' : '')
      );
    }
  });
  return buf.length === 0 ? undefined : buf.join(';');
};
