import type {
  CalendarDay,
  CalendarWeekAttribute,
} from '../../../../../shared/apiFetchers/variantsCalendarFetcher';

type DayData = {
  date: string;
  dayDisplay: string;
  dayFormatted: string;
  capacity: number;
  attributes: CalendarWeekAttribute[];
};

export const formatDatesForCalendarByWeek = (
  dates: string[],
  calendarDays: CalendarDay[]
): DayData[][] => {
  const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
  const firstDate = new Date(dates[0]);
  const lastDate = new Date(dates[dates.length - 1]);
  const calendarDates: DayData[][] = [];
  let week: DayData[] = [];

  for (let i = 0; i < firstDate.getDay(); i++) {
    week.push({ date: '', dayDisplay: '', dayFormatted: '', capacity: 0, attributes: [] });
  }

  for (let i = 0; i < dates.length; i++) {
    const date = new Date(dates[i]);
    const dayFormatted = `${date.getFullYear()}年${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}月${date.getDate().toString().padStart(2, '0')}日(${
      weekdays[date.getDay()]
    })`;

    const { capacity, attributes } = calendarDays.find((day) => day.date === dates[i]) || {};

    week.push({
      date: dates[i],
      dayDisplay: date.getDate().toString(),
      dayFormatted,
      capacity: capacity || 0,
      attributes: attributes || ['outOfRange'],
    });
    if (week.length === 7) {
      calendarDates.push(week);
      week = [];
    }
  }

  for (let i = lastDate.getDay(); i < 6; i++) {
    week.push({ date: '', dayDisplay: '', dayFormatted: '', capacity: 0, attributes: [] });
  }
  if (week.length > 0) {
    calendarDates.push(week);
  }

  return calendarDates;
};
