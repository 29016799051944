import { fetchWithStatus, type PromiseWithStatus } from '../../../utils/withStatusHandling';

export const rentifyApiFetcher = async <T>(
  endpoint: string,
  query = {},
  headers: { 'embed-domain': string }
): PromiseWithStatus<T> => {
  const serializedQuery = new URLSearchParams(query);

  // 環境変数よりエンドポイントのベースを設定
  // 例: https://api.rentify.dev
  // この値はesbuildのビルド時に置き換えられる
  const url = `${process.env.RENTIO_API_ENDPOINT_BASE}${endpoint}?${serializedQuery}`;

  const params = {
    method: 'GET',
    headers,
  };
  return fetchWithStatus<T>(url, params);
};
