import { h, Fragment } from '../../../../../shared/utils/tsxToDom';
import { VisuallyHiddenText } from '../../../../shared/components/VisuallyHiddenText';
import { injectRentalStart } from '../../../shared/uses/useRentalStart';
import { injectOrder } from '../../shared/uses/useOrder';
import { CalendarTableData } from './CalendarTableData';
import styles from './index.module.css';
import { getThreeMonthsDates } from './getThreeMonthsDates';
import { formatDatesForCalendarByWeek } from './formatDatesForCalendarByWeek';
import { trimDatesBeforeSpecifiedDateWeek } from './trimDatesBeforeSpecifiedDateWeek';

export const CalendarDatePicker = ({
  title,
  selectedDate,
  selectedFromDate,
  selectedToDate,
  onDateSelect,
  onDialogClose,
}: {
  title: string;
  selectedDate: string;
  selectedFromDate?: string;
  selectedToDate?: string;
  onDateSelect: (selectedDate: string) => void;
  onDialogClose: () => void;
}) => {
  const { getId } = injectRentalStart();
  const { getNowDate, getDeliveryDays } = injectOrder();
  const deliveryDays = getDeliveryDays();
  const nowDate = getNowDate();
  const monthDates = getThreeMonthsDates(nowDate);

  // 現在週より前の週は削除する
  // https://github.com/rentio/rentify-embed/issues/53
  if (monthDates.length > 0) {
    monthDates[0].dates = trimDatesBeforeSpecifiedDateWeek(nowDate, monthDates[0].dates);
  }

  return (
    <div class={styles.CalendarDatePicker}>
      <div class={styles._CalendarDialogTitle}>
        <div
          class={styles._CalendarDialogTitleText}
          role="heading"
          aria-level="1"
          id={getId('calendar-dialog-title')}
        >
          {title}
        </div>
        <button
          type="button"
          class={styles._CalendarDialogCloseButton}
          id={getId('calendar-dialog-close-button')}
          onClick={() => {
            onDialogClose();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
              fill="currentColor"
            />
          </svg>

          <VisuallyHiddenText label="閉じる" />
        </button>
      </div>
      <div class={styles._CalendarDialogBody}>
        <div class={styles._TableWrapper}>
          {/* 常に表示している表ヘッダ（見た目だけ残しaria-hiddenする） */}
          <div class={styles._TableHeader}>
            <div class={styles._Table} aria-hidden="true" role="table">
              <div class={styles._Thead} role="rowgroup">
                <div class={styles._Tr} role="row">
                  <div class={[styles._Th, '-sunday'].join(' ')}>
                    <span>日</span>
                  </div>
                  <div class={styles._Th} role="columnheader">
                    <span>月</span>
                  </div>
                  <div class={styles._Th} role="columnheader">
                    <span>火</span>
                  </div>
                  <div class={styles._Th} role="columnheader">
                    <span>水</span>
                  </div>
                  <div class={styles._Th} role="columnheader">
                    <span>木</span>
                  </div>
                  <div class={styles._Th} role="columnheader">
                    <span>金</span>
                  </div>
                  <div class={[styles._Th, '-saturday'].join(' ')} role="columnheader">
                    <span>土</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            <>
              {monthDates.map((monthData) => (
                <>
                  <div
                    class={styles._MonthHeader}
                    id={getId(`calendar-dialog-month-${monthData.yearMonth}`)}
                    role="heading"
                    aria-level="2"
                  >
                    {monthData.yearMonthDisplay}
                  </div>
                  <div
                    class={styles._Table}
                    aria-labelledby={[
                      getId('calendar-dialog-title'),
                      getId(`calendar-dialog-month-${monthData.yearMonth}`),
                    ].join(' ')}
                    role="table"
                  >
                    {formatDatesForCalendarByWeek(monthData.dates, deliveryDays).map((week) => (
                      <div class={styles._Tr} role="row">
                        {week.map(({ date, dayDisplay, dayFormatted, capacity, attributes }) => (
                          <CalendarTableData
                            date={date}
                            dayDisplay={dayDisplay}
                            dayFormatted={dayFormatted}
                            capacity={capacity}
                            attributes={attributes}
                            selectedDate={selectedDate}
                            selectedFromDate={selectedFromDate}
                            selectedToDate={selectedToDate}
                            onSelect={(selectedDate) => onDateSelect(selectedDate)}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </>
          }
        </div>
      </div>
    </div>
  );
};
