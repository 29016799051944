import { h, Fragment } from '../../../../shared/utils/tsxToDom';
import { injectRentalStart } from '../../shared/uses/useRentalStart';
import { HelpDialogOpener } from '../../../shared/components/HelpDialogOpener';
import { HelpPlanDialog } from './Dialog';
import { closeAnimatedDialog, openAnimatedDialog } from '../../../shared/utils/dialogHandlers';

export const PlanHelp = () => {
  const { getId } = injectRentalStart();
  const dialogId = getId('help-plan-dialog');

  let helpPlanDialogOpener: any = null;
  let helpPlanDialog: any = null;
  return (
    <>
      {
        (helpPlanDialogOpener = (
          <HelpDialogOpener
            id={getId('help-plan-dialog-open-button')}
            ariaControls={dialogId}
            onClick={() => {
              openAnimatedDialog(helpPlanDialog, helpPlanDialogOpener);
            }}
          />
        ))
      }
      {
        (helpPlanDialog = (
          <HelpPlanDialog
            id={dialogId}
            onDialogClose={() => {
              closeAnimatedDialog(helpPlanDialog, helpPlanDialogOpener);
            }}
          />
        ))
      }
    </>
  );
};
