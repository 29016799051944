
const digest = '429d8360db6d6e56d564e9ff59aca3f4e5d32563b94a50b263397dbda98dd75a';
const css = `._CalendarDatePickerOpener_l116e_1 {
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  height: 48px;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 1px solid var(--color-gray-200);
  border-radius: 6px;
  margin: 0;
  padding: 0 24px;
  background: transparent;
  color: inherit;
  font-size: 18px;
  font-weight: 400;
  font-family: Hiragino Kaku Gothic ProN, sans-serif;
  line-height: 1.55;
  color: var(--color-gray-800);
}

._CalendarDatePickerOpener_l116e_1:enabled {
  cursor: pointer;
}

._CalendarDatePickerOpener_l116e_1:disabled {
  opacity: 0.4;
}

._CalendarDatePickerOpener_l116e_1 svg {
  flex-shrink: 0;
}

._CalendarDatePickerOpener_l116e_1 span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"CalendarDatePickerOpener":"_CalendarDatePickerOpener_l116e_1","calendarDatePickerOpener":"_CalendarDatePickerOpener_l116e_1"};
export { css, digest };
  