import { id, initialize, reRenderApp } from '../../components/RentalStart';
import type { Settings } from '../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../shared/apiFetchers/embedProductsFetcher';
import { injectRentalStart } from '../../components/RentalStart/shared/uses/useRentalStart';

export const rentalStartLoader = ({
  embedDomain,
  target,
  settings,
  product,
}: {
  embedDomain: string;
  target: Element;
  settings: Settings;
  product: Product;
}) => {
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', id);
  target.appendChild(placeholder);
  initialize({ embedDomain, settings, product });
};

export const rentalStartReLoader = ({
  target,
}: {
  target: Element;
}) => {
  const { getId } = injectRentalStart();
  if (document.querySelector('#' + getId('rental-start-dialog-open-button'))) {
    // すでにレンタルボタンが存在していたら再描画しない
    return;
  }
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', id);
  target.appendChild(placeholder);
  reRenderApp();
}
