import type { PlanId, ProductStateId } from '../../../../../../shared/types';

type OfferType = 'rental' | 'monthly_rental' | 'unused_rental' | 'unused_monthly_rental' | 'sell';

export const getOfferType = ({
  productStateId,
  planId,
}: {
  productStateId: ProductStateId;
  planId: PlanId;
}): OfferType => {
  if (productStateId === 'reuse') {
    if (planId === 'onetime') return 'rental';
    if (planId === 'monthly') return 'monthly_rental';
  } else if (productStateId === 'new') {
    if (planId === 'onetime') return 'unused_rental';
    if (planId === 'monthly') return 'unused_monthly_rental';
    if (planId === 'sales') return 'sell';
  }
  throw new Error('productStateId+planIdが不正です');
};
