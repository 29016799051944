import { h, Fragment } from '../../../shared/utils/tsxToDom';
import { ButtonPrimary } from '../../shared/components/ButtonPrimary';
import { provideOrder, useOrder } from './shared/uses/useOrder';
import styles from './index.module.css';
import { FreeTextArea } from '../../shared/components/FreeTextArea';
import type { PlanId, ProductStateId } from '../../../shared/types';
import { injectRentalStart } from '../shared/uses/useRentalStart';
import type { PlanInfo } from './shared/uses/useOrder/getPlanInfo';
import { SvgCloseIcon } from '../../shared/components/SvgCloseIcon';
import { PlanHelp } from './PlanHelp';
import { CalendarDate } from './CalendarDate';
import { ReRender } from '../../../shared/utils/tsxToDom/components/ReRender';

export const RentalOrderForm = ({ onCancel }: { onCancel: () => void }) => {
  const { getId, product, embedDomain, settings } = injectRentalStart();
  const { front_domain: frontDomain } = settings;
  const orderCtx = provideOrder(useOrder({ embedDomain, frontDomain, product }));
  const isShowVariants = product.variants.length > 1;

  const OrderFields = ({ planInfo }: { planInfo: PlanInfo }) => {
    return (
      <div class={styles.OrderForm}>
        <div role="heading" aria-level="1" class={styles._Title}>
          {product.images.length && (
            <img src={orderCtx.getProductImageUrl()} alt={orderCtx.getProductImageAlt()} />
          )}
          <span class={styles._TitleLabel}>
            {orderCtx.getSelectedPlanId() === 'sales' ? '購入する商品' : 'レンタルする商品'}
          </span>
          <span class={styles._TitleName}>{product.name}</span>
        </div>
        <div class={styles._Detail}>
          <div class={styles._Form}>
            <div class={styles._FormItem}>
              <div class={styles._FormLabel}>
                <div
                  role="heading"
                  aria-level="2"
                  class={styles._FormLabelText}
                  id={getId('form-product-state-label')}
                >
                  商品の状態を選択
                </div>
              </div>
              <select
                class={styles._Select}
                ariaDescribedBy={getId('form-product-state-label')}
                onChange={(event: Event) => {
                  const id = (event.target as HTMLSelectElement).value as ProductStateId;
                  orderCtx.changeSelectedProductStateToRender(id);
                }}
              >
                {orderCtx.getSelectableProductState().map((state) => (
                  <option
                    value={state.id}
                    selected={state.id === orderCtx.getSelectedProductStateId()}
                  >
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            {isShowVariants && (
              <div class={styles._FormItem}>
                <div class={styles._FormLabel}>
                  <div
                    role="heading"
                    aria-level="2"
                    class={styles._FormLabelText}
                    id={getId('form-variants-label')}
                  >
                    <span>バリエーションを選択</span>
                  </div>
                </div>
                <select
                  class={styles._Select}
                  ariaDescribedBy={getId('form-variants-label')}
                  onChange={(event: Event) => {
                    const id = (event.target as HTMLSelectElement).value;
                    orderCtx.changeSelectedVariantToRender(id);
                  }}
                >
                  {product.variants.map((variant) => (
                    <option
                      value={variant.id}
                      selected={variant.id.toString() === orderCtx.getSelectedVariantId()}
                    >
                      {variant.options_text}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div class={styles._FormItem}>
              <div class={styles._FormLabel}>
                <div
                  role="heading"
                  aria-level="2"
                  class={styles._FormLabelText}
                  id={getId('form-plan-label')}
                  aria-describedby={getId('form-plan-changed')}
                >
                  <span>プランを選択</span>
                </div>
                <PlanHelp />
              </div>
              <select
                class={styles._Select}
                aria-describedby={[getId('form-plan-note')].join(' ')}
                onChange={(event: Event) => {
                  const id = (event.target as HTMLSelectElement).value as PlanId;
                  orderCtx.changeSelectedPlanToRender(id);
                }}
              >
                {orderCtx.getSelectablePlans().map((plan) => (
                  <option value={plan.id} selected={plan.id === orderCtx.getSelectedPlanId()}>
                    {plan.name}
                  </option>
                ))}
              </select>
              {orderCtx.getIsShowPlanChangeNotification() && (
                <FreeTextArea id="form-plan-changed">
                  <div role="paragraph">選択中の項目が変更されました</div>
                </FreeTextArea>
              )}
              <div role="list" class={styles._FormItemNote} id={getId('form-plan-note')}>
                {planInfo && (
                  <>
                    {planInfo.text && <div role="list-item">・{planInfo.text}</div>}
                    {planInfo.text2 && <div role="list-item">・{planInfo.text2}</div>}
                    {planInfo.text3 && <div role="list-item">・{planInfo.text3}</div>}
                    {planInfo.text4 && <div role="list-item">・{planInfo.text4}</div>}
                  </>
                )}
              </div>
            </div>
            <div class={styles._FormItem}>
              <div class={styles._FormLabel}>
                <div
                  class={styles._FormLabelText}
                  role="heading"
                  aria-level="2"
                  id={getId('form-delivery-date-label')}
                >
                  お届け日を選択
                </div>
              </div>
              <CalendarDate
                title={'お届け日を選択'}
                selectedDate={orderCtx.getSelectedDeliveryDate()}
                selectedToDate={orderCtx.getSelectedReturnDate()}
                ariaDescribedBy={[
                  getId('form-delivery-date-label'),
                  getId('form-delivery-date-error'),
                  getId('form-delivery-date-free-text-area'),
                ].join(' ')}
                onDateSelect={(newSelectedDate) =>
                  orderCtx.changeDeliveryDateToRender(newSelectedDate)
                }
              />
              <div class={styles._ErrorMessage} id={getId('form-delivery-date-error')}>
                {orderCtx.getDeliveryDateErrorMessages().map((message) => (
                  <div role="paragraph" class={styles._ErrorMessageText}>
                    {message}
                  </div>
                ))}
              </div>
              {orderCtx.getSelectedDeliveryDate() === '' && (
                <FreeTextArea id="form-delivery-date-free-text-area">
                  <div role="paragraph">ただいま在庫を準備中です。</div>
                </FreeTextArea>
              )}
            </div>

            {orderCtx.getIsShowReturnDate() && (
              <div class={styles._FormItem}>
                <div class={styles._FormLabel}>
                  <div
                    role="heading"
                    aria-level="2"
                    class={styles._FormLabelText}
                    id={getId('form-return-date-label')}
                  >
                    返送日を選択
                  </div>
                </div>
                <CalendarDate
                  title={'返送日を選択'}
                  selectedFromDate={orderCtx.getSelectedDeliveryDate()}
                  selectedDate={orderCtx.getSelectedReturnDate()}
                  ariaDescribedBy={[
                    getId('form-return-date-label'),
                    getId('form-return-date-error'),
                    getId('form-return-date-free-text-area'),
                  ].join(' ')}
                  onDateSelect={(newSelectedDate) =>
                    orderCtx.changeReturnDateToRender(newSelectedDate)
                  }
                />
                <div class={styles._ErrorMessage} id={getId('form-return-date-error')}>
                  {orderCtx.getReturnDateErrorMessages().map((message) => (
                    <div role="paragraph" class={styles._ErrorMessageText}>
                      {message}
                    </div>
                  ))}
                </div>
                <FreeTextArea id="form-return-date-free-text-area">
                  <div role="paragraph">{orderCtx.getSpecifiedPeriodInfo()}</div>
                </FreeTextArea>
              </div>
            )}
          </div>
          <div class={styles._PriceAndAddButton}>
            {/* <div role="paragraph" class={styles._Price}>
          <span class={styles._PriceOff}>1,000円OFF</span>
          <span class={styles._PriceTexts}>
            <span class={[styles._PriceText1, '-off'].join(' ')}>2,980円〜</span>
            <del class={styles._PriceText2}>3,980円〜</del>
          </span>
          <span class={styles._PriceNote}>(税込・送料込み)</span>
        </div> */}
            <ButtonPrimary
              disabled={
                orderCtx.getSelectedDeliveryDate() === '' ||
                orderCtx.getDeliveryDateErrorMessages().length > 0 ||
                orderCtx.getReturnDateErrorMessages().length > 0
              }
              type="button"
              onClick={() => {
                location.href = orderCtx.getNextPageUrl();
              }}
            >
              カートに入れる
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  orderCtx.fetchDeliveryDateToRender();

  return (
    <>
      <ReRender
        target={({ render }) => {
          orderCtx.setDialogContentRender(render);
          return <OrderFields planInfo={orderCtx.getPlanInfo()} />;
        }}
      />
      <button
        type="button"
        id={getId('rental-start-dialog-close-button')}
        class={styles._CloseButton}
        onClick={() => onCancel()}
      >
        <SvgCloseIcon />
        閉じる
      </button>
    </>
  );
};
