import type { CalendarWeekAttribute } from '../../../../../../shared/apiFetchers/variantsCalendarFetcher';
import { h } from '../../../../../../shared/utils/tsxToDom';
import { VisuallyHiddenText } from '../../../../../shared/components/VisuallyHiddenText';
import { getTableDataContent } from './getTableDataContent';
import styles from './index.module.css';

export const CalendarTableData = ({
  date,
  dayDisplay,
  dayFormatted,
  capacity,
  attributes,
  selectedDate,
  selectedFromDate,
  selectedToDate,
  onSelect,
}: {
  date: string;
  dayDisplay: string;
  dayFormatted: string;
  capacity: number;
  attributes: CalendarWeekAttribute[];
  selectedDate: string;
  selectedFromDate?: string;
  selectedToDate?: string;
  onSelect: (selectedDate: string) => void;
}) => {
  if (!dayDisplay) return <div class={styles.CalendarTableData}></div>;

  const { selected, disabled, content, classNames } = getTableDataContent({
    capacity,
    attributes,
    date,
    selectedDate,
    selectedFromDate,
    selectedToDate,
  });

  return (
    <div
      class={[...classNames, styles.CalendarTableData].join(' ')}
      aria-selected={selected}
      role="cell"
    >
      <button class={styles._Button} disabled={disabled} onClick={() => onSelect(date)}>
        <span class={styles._ButtonText1} aria-hidden="true">
          {dayDisplay}
        </span>
        <span
          class={[styles._TableStatusText, disabled ? '-outOfStock' : ''].join(' ')}
          aria-hidden="true"
        >
          {content}
        </span>
        <VisuallyHiddenText label={`${dayFormatted} ${capacity ? '在庫あり' : '在庫なし'}`} />
      </button>
    </div>
  );
};
