import { h } from '../../../../shared/utils/tsxToDom';

export const SvgCloseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99999 6.1751L9.88749 3.2876L10.7123 4.11243L7.82483 6.99993L10.7123 9.88743L9.88749 10.7123L6.99999 7.82476L4.11249 10.7123L3.28766 9.88743L6.17516 6.99993L3.28766 4.11243L4.11249 3.2876L6.99999 6.1751Z"
      fill="currentColor"
    />
  </svg>
);
