import { h } from '../../../../shared/utils/tsxToDom';
import { VisuallyHiddenText } from '../VisuallyHiddenText';
import styles from './index.module.css';

export const HelpDialogOpener = ({
  id,
  ariaControls,
  onClick,
}: {
  id?: string;
  ariaControls?: string;
  onClick?: (event: Event) => void;
}) => {
  return (
    <button
      type="button"
      id={id}
      aria-expanded="false"
      aria-controls={ariaControls}
      class={styles._HelpButton}
      onClick={onClick}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM6.183 6.78713H7.11112C7.26638 6.78713 7.39012 6.66 7.41037 6.50588C7.51162 5.76788 8.01787 5.23013 8.92013 5.23013C9.69187 5.23013 10.3984 5.616 10.3984 6.54413C10.3984 7.2585 9.97762 7.587 9.31275 8.0865C8.55562 8.63663 7.956 9.279 7.99875 10.3219L8.00212 10.566C8.00331 10.6398 8.03346 10.7102 8.08608 10.762C8.13869 10.8137 8.20956 10.8428 8.28337 10.8427H9.19575C9.27034 10.8427 9.34188 10.8131 9.39462 10.7604C9.44737 10.7076 9.477 10.6361 9.477 10.5615V10.4434C9.477 9.63562 9.78413 9.4005 10.6132 8.77162C11.2984 8.25075 12.0128 7.6725 12.0128 6.45863C12.0128 4.75875 10.5773 3.9375 9.00562 3.9375C7.58025 3.9375 6.01875 4.60125 5.91188 6.50925C5.91034 6.54558 5.91624 6.58184 5.92924 6.61581C5.94223 6.64977 5.96203 6.68072 5.98742 6.70674C6.01282 6.73277 6.04327 6.75333 6.0769 6.76715C6.11054 6.78097 6.14664 6.78777 6.183 6.78713ZM8.79862 14.0355C9.48487 14.0355 9.95625 13.5922 9.95625 12.9926C9.95625 12.3716 9.48375 11.9351 8.79862 11.9351C8.14163 11.9351 7.6635 12.3716 7.6635 12.9926C7.6635 13.5922 8.14163 14.0355 8.79975 14.0355H8.79862Z"
          fill="currentColor"
        />
      </svg>
      <VisuallyHiddenText label="ヘルプ" />
    </button>
  );
};
