
const digest = 'a177a2f08ff4f68555e1c2ff3eabffc49085ea49f280a5ccffd6d47a371c7c4f';
const css = `.__CalendarDialog_q5yvw_1 {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: revert;
  padding: 0;
  border: 0;
  transition: opacity 0.5s;
  overscroll-behavior: none;
  background: transparent;
  overflow: visible;
  color: inherit;
}

.__CalendarDialog_q5yvw_1[open] {
  display: flex;
  flex-direction: column;
}

.__CalendarDialog_q5yvw_1.-closing {
  opacity: 0;
}

.__CalendarDialog_q5yvw_1::backdrop {
  opacity: 0.5;
  background-color: #000;
  transition: opacity 0.5s;
}

.__CalendarDialog_q5yvw_1.-closing::backdrop {
  opacity: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"_CalendarDialog":"__CalendarDialog_q5yvw_1","calendarDialog":"__CalendarDialog_q5yvw_1"};
export { css, digest };
  