export type DataWithStatus<T> = { ok: true; data: T } | { ok: false; error: Error };

export type PromiseWithStatus<T> = Promise<DataWithStatus<T>>;

export const fetchWithStatus = async <T>(url: string, params: object): PromiseWithStatus<T> => {
  try {
    const res = await fetch(url, params);
    if (!res.ok) {
      throw Error(`${res.status} ${res.statusText}`);
    }
    const json = await res.json();
    return { ok: true, data: json };
  } catch (error: any) {
    return { ok: false, error };
  }
};
