
const digest = '17aa5f21309776b764a33d2b2cd2a8058046b84f4797c25fefa254b768c0959a';
const css = `._App_jlir7_1,
.__Dialog_jlir7_2 {
  color: var(--color-black);
  font-size: 16px;
  font-family: Hiragino Kaku Gothic ProN, sans-serif;
  font-weight: 300;
  line-height: 1.42;
  text-align: left;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.__Dialog_jlir7_2 {
  box-sizing: border-box;
  top: auto;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 480px;
  margin-inline: auto;
  padding: 24px 18px;
  border: 0;
  border-radius: 18px 18px 0 0;
  box-shadow: var(--shadow-dark-lg);
  background-color: var(--color-white);
  transition: opacity 0.5s, translate 0.5s, visibility 0.5s;
  overscroll-behavior: none;
  translate: 0;
}

.__Dialog_jlir7_2.-closing {
  visibility: hidden;
  opacity: 0;
  translate: 0 100%;
}

.__Dialog_jlir7_2::backdrop {
  opacity: 0.5;
  background-color: #000;
  transition: opacity 0.5s, visibility 0.5s;
}

.__Dialog_jlir7_2.-closing::backdrop {
  visibility: hidden;
  opacity: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"App":"_App_jlir7_1","app":"_App_jlir7_1","_Dialog":"__Dialog_jlir7_2","dialog":"__Dialog_jlir7_2"};
export { css, digest };
  