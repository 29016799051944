import { h } from '../../shared/utils/tsxToDom';
import type { RentalStartCtx } from './shared/uses/useRentalStart';
import styles from './App.module.css';
import custom from '../shared/cssModules/customProperties.modules.css';
import { ButtonPrimary } from '../shared/components/ButtonPrimary';
import { RentalOrderForm } from './OrderForm';
import { closeAnimatedDialog, openAnimatedDialog } from '../shared/utils/dialogHandlers';
import { domReplacer } from '../../shared/utils/tsxToDom/domReplacer';
import { AppDialog } from '../shared/components/AppDialog';
import { styleFormat } from '../../shared/utils/styleFormat';

export const App = ({ rentalStartCtx }: { rentalStartCtx: RentalStartCtx }) => {
  const { getId, openRentalDialog, closeRentalDialog, settings } = rentalStartCtx;

  let dialogDom: any = null;
  let dialogOpenerDom: any = null;

  const handleOrderCancel = () => {
    closeRentalDialog();
    closeAnimatedDialog(dialogDom as HTMLDialogElement, dialogOpenerDom);
  };

  const buttonStyle = styleFormat({
    'background-color': settings.primary_color,
    color: settings.font_color,
    'font-size': settings.font_size,
    'font-weight': settings.font_weight,
    'border-color': settings.border_color,
    'border-width': settings.border_width,
    'border-radius': settings.border_radius,
    width: settings.width,
    height: settings.height,
  });

  const { dom, render } = domReplacer(() => {
    const appDom = (
      <div class={[custom.properties, styles.App].join(' ')}>
        {
          (dialogOpenerDom = (
            <ButtonPrimary
              id={getId('rental-start-dialog-open-button')}
              style={buttonStyle}
              iconVisible={settings.use_icon}
              ariaExpanded="false"
              ariaControls={getId('rental-start-dialog')}
              onClick={() => {
                openRentalDialog();
                requestAnimationFrame(() => {
                  openAnimatedDialog(dialogDom as HTMLDialogElement, dialogOpenerDom);
                });
              }}
            >
              {settings.button_text || 'レンタルする'}
            </ButtonPrimary>
          ))
        }

        {rentalStartCtx.rentalDialogVisible && (
          <rentify-dialog-slot>
            {
              (dialogDom = (
                <AppDialog
                  id={getId('rental-start-dialog')}
                  class={styles._Dialog}
                  onCancel={() => handleOrderCancel()}
                >
                  <RentalOrderForm onCancel={() => handleOrderCancel()} />
                </AppDialog>
              ))
            }
          </rentify-dialog-slot>
        )}
      </div>
    );
    if (dialogDom && settings.modal_primary_color) {
      dialogDom.style.setProperty('--button-primary-bg-color', settings.modal_primary_color);
      dialogDom.style.setProperty('--selected-date-bg-color', settings.modal_primary_color);
    }
    return appDom;
  });

  rentalStartCtx.setRentalDialogRender(render);
  return dom;
};

class DialogSlot extends HTMLElement {
  private isUnderBody: boolean = false;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' }).innerHTML = `
        <slot></slot>
      `;
  }
  connectedCallback() {
    if (this.isUnderBody) {
      return;
    }
    const existingElement = document.querySelector('body > rentify-dialog-slot');
    if (existingElement) {
      existingElement.remove();
    }
    this.classList.add(custom.properties);
    if (this.parentNode !== document.body) {
      this.isUnderBody = true;
      document.body.appendChild(this);
    }
  }
}
customElements.define('rentify-dialog-slot', DialogSlot);
