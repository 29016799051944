import type { Product } from '../../../../../../shared/apiFetchers/embedProductsFetcher';
import type { PlanId, ProductStateId } from '../../../../../../shared/types';

export interface PlanInfo {
  text?: string;
  text2?: string;
  text3?: string;
  text4?: string;
}

const numberFormat = (number: number) => Number(number).toLocaleString('ja-JP');
const periodFormat = (period: number) => (period ? `${period}ヶ月` : 'なし');

export const getPlanInfo = ({
  product,
  productStateId,
  planId,
}: {
  product: Product;
  productStateId: ProductStateId;
  planId: PlanId;
}): PlanInfo => {
  const {
    rental_offer,
    monthly_rental_offer,
    unused_monthly_rental_offer,
    unused_rental_offer,
    sell_offer,
  } = product;
  if (productStateId === 'reuse' && planId === 'onetime' && rental_offer) {
    const period = Number(rental_offer.minimum_period || 0);
    return {
      text: `標準レンタル期間：${period}泊${period + 1}日`,
      text2: `標準レンタル料金：${numberFormat(rental_offer.price)}円`,
      text3: `延長：${numberFormat(rental_offer.price_per_unit)}円/日`,
      text4: rental_offer.monthly_rental_after_offer ? '途中で月額制に切替可能' : '',
    };
  }
  if (productStateId === 'reuse' && planId === 'monthly' && monthly_rental_offer) {
    const { minimum_period, maximum_period, price } = monthly_rental_offer;
    return {
      text: `最低レンタル期間：${periodFormat(minimum_period)}`,
      text2: `標準月額料金: ${numberFormat(price)}円/月`,
      text3: `月額課金の自動終了: ${periodFormat(maximum_period)}`,
    };
  }
  if (productStateId === 'new' && planId === 'monthly' && unused_monthly_rental_offer) {
    const { minimum_period, maximum_period, price } = unused_monthly_rental_offer;
    return {
      text: `最低レンタル期間：${periodFormat(minimum_period)}`,
      text2: `標準月額料金: ${numberFormat(price)}円/月`,
      text3: `月額課金の自動終了: ${periodFormat(maximum_period)}`,
    };
  }
  if (productStateId === 'new' && planId === 'onetime' && unused_rental_offer) {
    const period = Number(unused_rental_offer.minimum_period || 0);
    return {
      text: `標準レンタル期間：${period}泊${period + 1}日`,
      text2: `標準レンタル料金：${numberFormat(unused_rental_offer.price)}円`,
      text3: `延長：${numberFormat(unused_rental_offer.price_per_unit)}円/日`,
      text4: unused_rental_offer.monthly_rental_after_offer ? '途中で月額制に切替可能' : '',
    };
  }
  if (productStateId === 'new' && planId === 'sales' && sell_offer) {
    const { price } = sell_offer;
    return {
      text: `標準販売価格: ${numberFormat(price)}円`,
    };
  }
  return {};
};
