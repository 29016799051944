import type { PlanId, ProductStateId } from '../../types';
import type { PromiseWithStatus } from '../../utils/withStatusHandling';
import { rentifyApiFetcher } from '../shared/rentifyApiFetcher';

export type CalendarWeekAttribute =
  | 'today'
  | 'weekday'
  | 'saturday'
  | 'sunday'
  | 'holiday'
  | 'outOfRange'
  | 'unselectable';

export interface CalendarDay {
  date: string;
  capacity: number;
  attributes: CalendarWeekAttribute[];
}

type CalendarWeek = Array<CalendarDay>;

interface VariantsRentalCalendarData {
  calendar: {
    weeks: Array<CalendarWeek>;
    nearestSelectableDate: string;
    message: string;
  };
}

export const variantsCalendarFetcher = async ({
  embedDomain,
  variantId,
  productStateId,
  planId,
}: {
  embedDomain: string;
  variantId: string;
  productStateId: ProductStateId;
  planId: PlanId;
}): PromiseWithStatus<VariantsRentalCalendarData> => {
  const getOfferType = () => {
    if (productStateId === 'reuse') {
      if (planId === 'onetime') return 'rental';
      if (planId === 'monthly') return 'monthly_rental';
    } else if (productStateId === 'new') {
      if (planId === 'onetime') return 'unused_rental';
      if (planId === 'monthly') return 'unused_monthly_rental';
      if (planId === 'sales') return 'sell';
    }
    throw new Error('productStateId+planIdが不正です');
  };

  const endPoint = `/variants/${variantId}/calendar`;
  const offerType = getOfferType();
  return rentifyApiFetcher<VariantsRentalCalendarData>(
    endPoint,
    { offer_type: offerType },
    { 'embed-domain': embedDomain }
  );
};
