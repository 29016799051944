import { h } from '../../../../shared/utils/tsxToDom';

export const AppDialog = (
  {
    id,
    class: className,
    onCancel,
  }: {
    id?: string;
    class?: string;
    onCancel?: (event: Event) => void;
  },
  children: Element[]
) => {
  return (
    <dialog
      class={[className, '-closing'].join(' ')}
      id={id}
      onClick={(event: MouseEvent) => {
        // キーボード操作によるクリックイベントは無視する
        if (event.detail === 0) {
          return;
        }
        if (event.currentTarget instanceof Element) {
          event.stopPropagation();
          const { currentTarget, clientX, clientY } = event;
          const { top, left, width, height } = currentTarget?.getBoundingClientRect();
          // ::backdropのクリックイベントは拾えないため、
          // ダイアログ内をクリックしたか、ダイアログ外（≒::backdrop）をクリックしたかを判定する
          const isInDialog =
            top <= clientY && clientY <= top + height && left <= clientX && clientX <= left + width;
          if (isInDialog) {
            return;
          }
          onCancel?.(event);
        }
      }}
      onCancel={(event: Event) => {
        event.preventDefault();
        onCancel?.(event);
      }}
    >
      {children}
    </dialog>
  );
};
