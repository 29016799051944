import type { CalendarDay } from '../../../../../../shared/apiFetchers/variantsCalendarFetcher';

export const pickNowDateFromCalendar = (calendarDays: CalendarDay[]): string => {
  const nowDay = calendarDays.find((day) => day.attributes.find((attr) => attr === 'today'));
  if (nowDay) {
    return nowDay.date;
  }
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  return formattedDate;
};
