
const digest = 'aed2a42752aa0cd74a3529bb12d0e669e14804f2d7f1da1e082db65c4ad44d38';
const css = `._VisuallyHiddenText_1svoy_1 {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"VisuallyHiddenText":"_VisuallyHiddenText_1svoy_1","visuallyHiddenText":"_VisuallyHiddenText_1svoy_1"};
export { css, digest };
  