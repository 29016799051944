import type { Settings } from '../../../../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../../../../shared/apiFetchers/embedProductsFetcher';
import type { RuntimeIdentifier } from '../../../../../shared/utils/getRuntimeIdentifier';

export type RentalStartCtx = ReturnType<typeof useRentalStart>;

let cache: RentalStartCtx | null = null;

export const provideRentalStart = (v: RentalStartCtx): RentalStartCtx => {
  if (cache !== null) {
    throw new Error('RentalStart is already provided');
  }
  cache = v;
  return v;
};

export const injectRentalStart = (): RentalStartCtx => {
  if (cache === null) {
    throw new Error('RentalStart is not provided');
  }
  return cache;
};

export const useRentalStart = ({
  embedDomain,
  runtimeIdentifier,
  settings,
  product,
}: {
  embedDomain: string;
  runtimeIdentifier: RuntimeIdentifier;
  settings: Settings;
  product: Product;
}) => {
  if (cache !== null) {
    throw new Error('RentalStart is already provided');
  }

  const state = {
    rentalDialogVisible: false,
  };

  const handler = {
    rentalDialogRender: () => {},
  };

  return {
    get embedDomain() {
      return embedDomain;
    },
    get settings() {
      return settings || {};
    },

    get product() {
      return product;
    },

    get rentalDialogVisible() {
      return state.rentalDialogVisible;
    },

    getId(key: string) {
      return runtimeIdentifier.getId(key);
    },

    setRentalDialogRender(v: () => void) {
      handler.rentalDialogRender = v;
    },

    openRentalDialog() {
      state.rentalDialogVisible = true;
      handler.rentalDialogRender();
    },

    closeRentalDialog() {
      state.rentalDialogVisible = false;
    },
  };
};
