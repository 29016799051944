import type { MonthData } from './types';

export const trimDatesBeforeSpecifiedDateWeek = (
  baseDateStr: string,
  dates: MonthData['dates']
): MonthData['dates'] => {
  const baseDate = new Date(baseDateStr);
  const baseDayOfWeek = baseDate.getDay();
  const baseSunday = new Date(baseDate);
  baseSunday.setDate(baseDate.getDate() - baseDayOfWeek);
  return dates.filter((date) => new Date(date) >= baseSunday);
};
