
const digest = 'b41b2e150ef54031b0b70077a294fa7a25bb963923a703cb31ce1437cbd98a0c';
const css = `._FreeTextArea_cb07w_1 {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: var(--color-gray-100);
  color: var(--color-gray-700);
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
}

._FreeTextArea_cb07w_1 > svg{
  flex-shrink: 0;
}

.__Text_cb07w_18 > :first-child {
  margin-block-start: 0;
}

.__Text_cb07w_18 > :last-child {
  margin-block-end: 0;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"FreeTextArea":"_FreeTextArea_cb07w_1","freeTextArea":"_FreeTextArea_cb07w_1","_Text":"__Text_cb07w_18","text":"__Text_cb07w_18"};
export { css, digest };
  