window.RENTIFY_FRONTEND = window.RENTIFY_FRONTEND || {
  sequentialNumber: 0,
};

const getSequentialNumber = () => {
  window.RENTIFY_FRONTEND.sequentialNumber += 1;
  return window.RENTIFY_FRONTEND.sequentialNumber;
};

export type RuntimeIdentifier = ReturnType<typeof getRuntimeIdentifier>;

export const getRuntimeIdentifier = () => {
  const seq = getSequentialNumber();
  return {
    getId(key: string) {
      return `ss-${seq}-${key}`;
    },
  };
};
