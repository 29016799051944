import type { PromiseWithStatus } from '../../utils/withStatusHandling';
import { rentifyApiFetcher } from '../shared/rentifyApiFetcher';

interface ProductImage {
  id: number;
  public_image_url: string;
  alt: string;
  variant_id: number;
}

interface ProductVariant {
  id: number;
  options_text: string;
  sku: string;
}

export interface Product {
  id: number;
  name: string;
  images: ProductImage[];
  variants: ProductVariant[];

  rental_offer?: {
    price: number;
    price_per_unit: number;
    minimum_period: number;
    sell_after_offer: {
      price: number;
    };
    monthly_rental_after_offer: {
      price: number;
      minimum_period: number;
      maximum_period: number;
      sell_after_offer: {
        price: number;
      };
    };
    can_extend: true;
  };

  monthly_rental_offer?: {
    price: number;
    minimum_period: number;
    maximum_period: number;
    sell_after_offer: {
      price: number;
    };
  };

  unused_monthly_rental_offer?: {
    price: number;
    minimum_period: number;
    maximum_period: number;
    sell_after_offer: {
      price: number;
    };
  };

  unused_rental_offer?: {
    price: number;
    price_per_unit: number;
    minimum_period: number;
    can_sell_after_unused_rental: boolean;
    monthly_rental_after_offer: {
      price: number;
      minimum_period: number;
      maximum_period: number;
      can_sell_after_monthly_rental: boolean;
    };
  };

  sell_offer?: {
    price: number;
  };

  default_selection: {
    condition: 'used' | 'unused';
    plan: 'monthly_rental' | 'rental' | 'sell';
    option?: {};
    highlight?: {
      condition: boolean;
      plan: boolean;
      option: {};
    };
  };
}

interface EmbedProductsData {
  product: Product;
}

const baseEndPoint = '/embed/products';

export const embedProductsFetcher = async ({
  embedDomain,
  slug,
}: {
  embedDomain: string;
  slug: string;
}): PromiseWithStatus<EmbedProductsData> => {
  // OKパターン
  // return {
  //   ok: true,
  //   data: {},
  // };

  // NGパターン
  // return {
  //   ok: false,
  //   error: new Error('商品詳細取得APIでエラーが発生しました！'),
  // };

  // 本来の実装
  return await rentifyApiFetcher<EmbedProductsData>(
    `${baseEndPoint}/${slug}`,
    {},
    { 'embed-domain': embedDomain }
  );
};
