import { h, Fragment } from '../../../../../shared/utils/tsxToDom';
import { AppDialog } from '../../../../shared/components/AppDialog';
import styles from './index.module.css';
import { injectRentalStart } from '../../../shared/uses/useRentalStart';

export const HelpPlanDialog = ({
  id,
  onDialogClose,
}: {
  id: string;
  onDialogClose: () => void;
}) => {
  const { getId, product } = injectRentalStart();

  return (
    <AppDialog id={id} class={styles.HelpDialog} onCancel={() => onDialogClose()}>
      <div class={styles._HelpDialogInner}>
        <div class={styles._HelpDialogTitle}>
          <div role="heading" aria-level="1" class={styles._HelpDialogTitleText}>
            プランについて
          </div>
          <button
            type="button"
            class={styles._HelpDialogCloseButton}
            aria-label="閉じる"
            id={getId('help-plan-dialog-close-button')}
            onClick={() => {
              onDialogClose();
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 14.6669L20.6669 10L22 11.3331L17.3331 16L22 20.6669L20.6669 22L16 17.3331L11.3331 22L10 20.6669L14.6669 16L10 11.3331L11.3331 10L16 14.6669Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div class={styles._HelpDialogBody}>
          {(product.monthly_rental_offer || product.unused_monthly_rental_offer) && (
            <>
              <div role="heading" aria-level="2" class={styles._HelpDialogBodyHeadingText}>
              月額制プランとは
              </div>
              <div role="paragraph" class={styles._HelpDialogBodyText}>
              レンタル期間が「月数」で管理される契約方法です。
              <br />
              <br />
              返送期限はありません。利用をやめたい月に商品を返送すると、自動的にレンタル終了となります。
              <br />
              <br />
              所定の最低レンタル期間がある場合があります。もし所定の最低レンタル期間未満で返却した場合でも、最低レンタル期間分の月額料が発生します。
              <br />
              <br />
              支払い満了期間が設定されている商品では、月額料金の支払い満了と同時に課金が自動的に終了します。商品の所有権はお客様に移転し、返却は不要になります。
              </div>
            </>
          )}
          {(product.rental_offer || product.unused_rental_offer) && (
            <>
              <div role="heading" aria-level="2" class={styles._HelpDialogBodyHeadingText}>
              ワンタイムプランとは
              </div>
              <div role="paragraph" class={styles._HelpDialogBodyText}>
              商品のレンタル期間が「日数」で管理されるプランです。
              <br />
              <br />
              ワンタイムプランには「返送日」があります。
              <br />
              商品がお客様のお手元を離れる日が返送日（レンタル期間の最終日）です。
              <br />
              <br />
              標準期間はあくまで最短のレンタル日数であり、延長も可能です。
              <br />
              延長をすることで、より長い日数を指定することもできます。
              </div>
            </>
          )}
        </div>
      </div>
    </AppDialog>
  );
};
