import type { MonthData } from './types';

export const getThreeMonthsDates = (baseDateStr: string): MonthData[] => {
  const baseDate = new Date(baseDateStr);
  const startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
  const endDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + 3, 0);
  const monthsData: MonthData[] = [
    { yearMonth: '', yearMonthDisplay: '', dates: [] },
    { yearMonth: '', yearMonthDisplay: '', dates: [] },
    { yearMonth: '', yearMonthDisplay: '', dates: [] },
  ];

  for (let d = startDate, i = 0; d <= endDate; d.setDate(d.getDate() + 1)) {
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const date = d.getDate().toString().padStart(2, '0');
    const yearMonth = `${year}-${month}`;
    const yearMonthDisplay = `${year}年${month}月`;

    if (monthsData[i].yearMonth !== yearMonth) {
      monthsData[i].yearMonth = yearMonth;
      monthsData[i].yearMonthDisplay = yearMonthDisplay;
    }

    monthsData[i].dates.push(`${year}-${month}-${date}`);

    if (d.getDate() === new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()) {
      i++;
    }
  }
  return monthsData;
};
