import type { Product } from '../../../../../../shared/apiFetchers/embedProductsFetcher';
import type { ProductState, DefaultCondition } from '../../../../../../shared/types';

export const getSelectableProductState = (product: Product): Array<ProductState> => {
  return [
    ...((product.rental_offer || product.monthly_rental_offer
      ? [{ id: 'reuse', name: '点検済みリユース品' }]
      : []) as Array<ProductState>),
    ...((product.unused_monthly_rental_offer || product.sell_offer || product.unused_rental_offer
      ? [{ id: 'new', name: '新品' }]
      : []) as Array<ProductState>),
  ];
};

export const getDefaultProductStateId = (
  productStateItems: Array<ProductState>,
  defaultCondition?: DefaultCondition
): '' | 'reuse' | 'new' => {
  if (!defaultCondition) {
    return productStateItems.length > 0 ? productStateItems[0].id : '';
  }
  if (defaultCondition === 'used') {
    const reuseItem = productStateItems.find((item) => item.id === 'reuse');
    if (reuseItem) {
      return reuseItem.id;
    }
  }
  if (defaultCondition === 'unused') {
    const newItem = productStateItems.find((item) => item.id === 'new');
    if (newItem) {
      return newItem.id;
    }
  }
  return '';
};
