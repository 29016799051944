import type { PlanId, ProductStateId } from '../../../../../../shared/types';
import { getOfferType } from './getOfferType';

export const getNextPageUrl = ({
  frontDomain,
  variantId,
  productStateId,
  planId,
  deliveryDate,
  returnDate,
}: {
  frontDomain: string;
  variantId: string;
  productStateId: ProductStateId;
  planId: PlanId;
  deliveryDate: string;
  returnDate: string;
}): string => {
  const offerType = getOfferType({ productStateId, planId });
  const serializedQuery = new URLSearchParams({
    variant_id: variantId,
    offer_type: offerType,
    quantity: '1',
    rental_start_date: deliveryDate,
    ...(returnDate ? { rental_return_date: returnDate } : {}),
  });
  const url = `https://${frontDomain}/cart/add?${serializedQuery}`;
  return url;
};
